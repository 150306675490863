const init = () => {
    //  ===========================================
    //  = Load scripts based on modernizer tests  =
    //  ===========================================

    // Object fit polyfill for IE11 - Edge comments and test below taken from objectFitPolyfill.js source
    // Workaround for Edge 16+, which only implemented object-fit for <img> tags
    // TODO: Keep an eye on Edge to determine which version has full final support

    let edgeVersion = window.navigator.userAgent.match(/Edge\/(\d{2})\./);
    let edgePartialSupport = edgeVersion
        ? parseInt(edgeVersion[1], 10) >= 16
        : false;

    // If the browser doesn't support object-fit
    if (
        !(
            'objectFit' in document.documentElement.style !== false &&
            !edgePartialSupport
        )
    ) {
        $.getScript('/Static/build/js/objectFitPolyfill.js', () => {
            objectFitPolyfill();
        });
    }

    if (!Modernizr.picture) {
        $.getScript('/Static/build/js/picturefill.min.js');
    }

    // exammple of a method being invoked after a script

    // if(!Modernizr.input.placeholder) {
    //   $.getScript('/Static/build/js/jquery.placeholder.min.js')
    //     .done(() => {
    //       $('input, textarea').placeholder();
    //     });
    // }
    //
    //
};

export { init };
