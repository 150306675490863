const init = () => {
    // this adds the touch start listeners to all the interactable elements in the page
    window.onload = () => {
        if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
            const elements = document.querySelectorAll('button');
            const emptyFunction = () => {
                console.log('');
            };

            for (let i = 0; i < elements.length; i++) {
                elements[i].addEventListener(
                    'touchstart',
                    emptyFunction,
                    false
                );
            }
        }
    };
};

export { init };
