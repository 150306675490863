import { config } from '../config';

const options = {
    primaryNav: '#primary-nav',
    primaryNavList: '#primary-nav-list',
    navLinks: '#primary-nav-list li',
    mobileNavIcon: '.mobile-nav-icon',
    navButtons: '.nav-button',
    subNavs: '.nav-level-2',
    activeSubButton: ''
};

const init = () => {
    createNavigation();

    enquire.register(`screen and (max-width: ${config.breakpoint.mdMax}px)`, {
        match: function() {
            createMobileMenu();
        },
        unmatch: function() {
            destroyMobileMenu();
        }
    });

    enquire.register(`screen and (min-width: ${config.breakpoint.lgMin}px)`, {
        match: function() {
            createDesktopMenu();
        },
        unmatch: function() {
            destroyDesktopMenu();
        }
    });
};

const createNavigation = () => {
    $(options.navLinks)
        .has('ol')
        .each((index, el) => {
            // create IDs for the heading and content for each accordion item to associate each heading with its content
            let $this = $(el);
            let buttonID = 'nav-heading-' + index;
            let contentID = 'nav-accordion-' + index;
            let $firstLink = $this.find('a:first');

            // Add a button inside the <h2>
            let buttonText = $firstLink.data('nav-title');
            $firstLink.after(
                `<button id="${buttonID}" class="nav-button" aria-expanded="false" aria-controls="${contentID}">${buttonText}</button>`
            );

            let $subNav = null;
            $subNav = $this.find('ol:first');
            $subNav.attr('ID', contentID);
            $subNav.attr('aria-labelledby', buttonID);
            $subNav.attr('aria-hidden', 'true');

            // move the top level link into the child list
            let linkText = $firstLink.text();
            let linkUrl = $firstLink.attr('href');
            let newListItem = $(
                `<li class="nav-level-2__item"><a href="${linkUrl}" class="nav-level-2__link">${linkText}</a></li>`
            );
            $subNav.prepend(newListItem);

            // delete the original item
            $firstLink.remove();
        });

    // add click handler for subnavs
    $('.nav-button').on('click', e => {
        let $this = $(e.target);

        if ($this.hasClass('is-active')) {
            closeNavItem();
        } else {
            openNavItem(e.target);
        }
    });

    // handle tabbing through subnavs - doesn't work on mobile/tablet
    $('html').on(
        'focus',
        'nav-button, .nav-level-1__link, .secondary-nav__link',
        () => {
            if (options.activeSubButton) {
                closeSubMenuItems();
            }
        }
    );

    // close mobile menu by pressing escape
    // close open submenus on desktop by pressing escape
    // may need a more centralised approach for handling key codes
    $(window).keyup(e => {
        if (e.keyCode === 27) {
            e.preventDefault();

            // if mobile nav is open, close it
            if ($('html').hasClass('nav-active')) {
                $('html').removeClass('nav-active');
                // set focus back on the element that opened the modal
                $('#mobile-nav-icon').focus();
            }

            // close any open sub-menus on both mobile and desktop
            $('.nav-button.is-active').click();
        }
    });
};

const closeSubMenuItems = () => {
    let $el = options.activeSubButton;
    $el.removeClass('is-active');
    $el.attr('aria-expanded', false);
    $el.next().attr('aria-hidden', true);
    $el.next().slideToggle();
    options.activeSubButton = '';
};

const openNavItem = el => {
    let $el = $(el);

    // close any open subnavs
    if (options.activeSubButton) {
        closeNavItem();
    }

    options.activeSubButton = $el;

    $el.addClass('is-active').attr('aria-expanded', true);

    $el.next()
        .attr('data-tsat', 'nav-sliding')
        .slideDown(400, function() {
            $(this).removeAttr('data-tsat');
        })
        .attr('aria-hidden', false);
};

const closeNavItem = () => {
    $(options.activeSubButton)
        .removeClass('is-active')
        .attr('aria-expanded', false);

    $(options.activeSubButton)
        .next()
        .slideUp()
        .attr('aria-hidden', true);

    options.activeSubButton = '';
};

const createMobileMenu = () => {
    console.log('CMM');

    if ($(options.primaryNav).length) {
        // append open button
        $(
            '<button type="button" id="mobile-nav-icon" class="mobile-nav-icon">Open Menu</button>'
        ).insertBefore('#site-search');

        // append close button
        $(
            '<button type="button" id="mobile-nav-icon-2" class="mobile-nav-icon mobile-nav-icon--alt">Close Menu</button>'
        ).prependTo('#primary-nav');

        $(options.primaryNav).attr('aria-hidden', 'true');

        $(options.mobileNavIcon).each((index, el) => {
            console.log('whut');

            let $this = $(el);

            // add ARIA attributes
            $this.attr('aria-controls', $(options.primaryNav).attr('ID'));
            $this.attr('aria-expanded', 'false');

            $this.on('click', e => {
                let state =
                    $this.attr('aria-expanded') === 'false' ? true : false;
                $(options.mobileNavIcon).attr('aria-expanded', state);
                $(options.primaryNav).attr('aria-hidden', !state);
                $('html').toggleClass('nav-active');

                // set focus on the coresponding other open/close button
                let $primaryNavLink = $('a').not('#primary-nav a');

                if ($(e.target).attr('id') === 'mobile-nav-icon') {
                    // hide elements behind the nav overlay from focus
                    $primaryNavLink
                        .attr('tabindex', '-1')
                        .attr('style', 'cursor:default;');
                    $('button')
                        .not('#primary-nav button')
                        .attr('disabled', 'true');
                    $('input')
                        .not('#primary-nav input')
                        .attr('disabled', 'true');

                    $('#mobile-nav-icon-2').focus();
                } else {
                    // restore focus to elements behind the nav overlay
                    $primaryNavLink
                        .attr('tabindex', '0')
                        .attr('style', 'cursor:pointer;');
                    $('button')
                        .not('#primary-nav button')
                        .removeAttr('disabled');
                    $('input')
                        .not('#primary-nav input')
                        .removeAttr('disabled');

                    $('#mobile-nav-icon').focus();
                }
            });
        });
    }

    // clone and append logo dynamically to the mobile overlay
    $('.site-logo:first')
        .clone()
        .addClass('site-logo--alt')
        .prependTo('#primary-nav');

    // clone and append secondary nav
    $('.secondary-nav:first')
        .clone()
        .addClass('secondary-nav--alt')
        .insertAfter('#primary-nav-list');
};

const destroyMobileMenu = () => {
    // remove cloned logo
    $('.site-logo--alt').remove();

    // remove secondary nav
    $('.secondary-nav--alt').remove();

    // remove ARIA attribute on wrapper
    $(options.primaryNav).removeAttr('aria-hidden');

    // unbind click events
    $(options.mobileNavIcon).off('click');

    // remove focus handling
    $('.nav-button, .nav-level-1__link, secondary-nav__link').off('focus');

    // remove mobile nav button
    $(options.mobileNavIcon).remove();

    // remove modal state
    $('html').removeClass('nav-active');

    // close any open subnavs
    $('.nav-button.is-active').click();

    // restore focus to elements behind the nav overlay
    $('a')
        .not('#primary-nav a')
        .attr('tabindex', '0');
    $('a')
        .not('#primary-nav a')
        .attr('style', 'cursor:pointer;');
    $('button')
        .not('#primary-nav button')
        .removeAttr('disabled');
    $('input')
        .not('#primary-nav input')
        .removeAttr('disabled');
};

const createDesktopMenu = () => {
    // bind click event to the document to close any open sub-menus
    $('html').on('click, touchstart', event => {
        if ($(event.target).closest('#primary-nav').length === 0) {
            $('.nav-button.is-active').click();
        }
    });

    $(document).on('click', event => {
        event.stopPropagation();

        // check if the clicked area is dropDown or not
        if ($('#primary-nav').has(event.target).length === 0) {
            closeNavItem();
        }
    });
};

const destroyDesktopMenu = () => {
    $('.nav-button.is-active').click();
    $('html').off('click');
};

export { init };
