// https://inclusive-components.design/cards/

const init = () => {
    let keydown = false;

    // fix in place to stop duplicate _blank windows opening when 'noopener noreferrer' has been added
    $('.js-card a')
        .keydown(() => {
            keydown = true;
        })
        .click(e => {
            if ($(e.currentTarget).attr('target') === '_blank' && !keydown) {
                return false; // cancel the event
            }
            keydown = false;
        });

    const cards = document.querySelectorAll('.js-card');

    Array.prototype.forEach.call(cards, card => {
        let down,
            up,
            link = card.querySelector('a');
        card.style.cursor = 'pointer';

        card.onmousedown = () => {
            down = +new Date();
        };

        card.onmouseup = e => {
            e.stopPropagation();
            if (e.button === 0) {
                up = +new Date();
                if (up - down < 200) {
                    if (
                        $(e.currentTarget)
                            .find('a')
                            .attr('target') === '_blank'
                    ) {
                        let newWnd = window.open(
                            $(e.currentTarget)
                                .find('a')
                                .attr('href')
                        );
                        newWnd.opener = null;
                    } else {
                        link.click();
                    }
                }
            }
        };
    });
};

export { init };
