const init = () => {
    console.log('CP');
    const $cookiePanel = $('#cookie');

    if (Cookies && Cookies.get('privacy_cookie')) {
        return;
    } else {
        if (Cookies) {
            // for accessibility, prepend the cookie popup so it is immediately tabbable to in the DOM
            $cookiePanel.show();
        }
    }

    // remove pop-up and set cookie
    $('.js-cookie-policy-trigger').click(event => {
        console.log('ok');
        event.preventDefault();
        if (!Cookies.get('privacy_cookie')) {
            Cookies.set('privacy_cookie', 1, {
                expires: 365,
                path: '/'
            });

            $cookiePanel.slideUp().fadeOut();
            // console.log($.cookie('privacy_cookie'));
        }
    });
};

export { init };
