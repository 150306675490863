const config = {
    breakpoint: {
        xsMax: '575',
        smMin: '576',
        smMax: '767',
        mdMin: '768',
        mdMax: '991',
        lgMin: '992',
        lgMax: '1199',
        xlMin: '1200'
    }
};

export { config };
