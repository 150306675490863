const init = () => {
    $('.quote-block-carousel').slick({
        dots: false,
        adaptiveHeight: true,
        autoplay: false,
        autoplaySpeed: 5000,
        swipeToSlide: true,
        prevArrow: $('.js-quote-carousel .arrow-prev'),
        nextArrow: $('.js-quote-carousel .arrow-next')
    });

    $('.carousel').slick({
        dots: false,
        adaptiveHeight: true,
        autoplay: false,
        autoplaySpeed: 5000,
        swipeToSlide: true,
        prevArrow: $('.js-carousel-arrows .arrow-prev'),
        nextArrow: $('.js-carousel-arrows .arrow-next')
    });

    $('.services-carousel').slick({
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: $('.js-services-carousel-arrows .arrow-prev'),
        nextArrow: $('.js-services-carousel-arrows .arrow-next'),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
};

export { init };
