const init = () => {
    // we need to apply a focusin/focusoutto cards due to the way the links are applied
    // we are attaching a class which in turn allows us to hook up some CSS
    $('.js-card').focusin(() => {
        $(this).addClass('on-focus');
    });

    $('.js-card').focusout(() => {
        $(this).removeClass('on-focus');
    });

    // fix for Umbraco forms single checkbox element to enable custom styling
    // also places the label to the right of the checkbox instead of above it
    $('.umbraco-forms-field.checkbox').each((index, el) => {
        let $this = $(el);
        $this.find('label').insertAfter($this.find('input'));
    });
};

export { init };
