const options = {
    accordion: '.js-accordion',
    accordionTrigger: '.js-accordion-trigger'
};

const init = () => {
    $(options.accordionTrigger).each((index, el) => {
        // create IDs for the heading and content for each accordion item to associate each heading with its content
        let $this = $(el);
        let $accordionContent = $this.next();
        let buttonID = `heading-${index}`;
        let contentID = `accordion-${index}`;
        let $button;

        $button = $(
            `<button type="button" id="${buttonID}" class="accordion-panel__button" aria-expanded="false" aria-controls="${contentID}">${$this.html()}</button>`
        );

        // Add a button inside the <h2>
        $this.html($button);

        $accordionContent
            .attr('ID', contentID)
            .attr('aria-labelledby', buttonID)
            .attr('aria-hidden', 'true');

        $button.on('click', () => {
            let state =
                $button.attr('aria-expanded') === 'false' ? true : false;

            $button
                .stop() // added to stop click events queuing up
                .attr('aria-expanded', state)
                .toggleClass('is-active');

            $accordionContent
                .stop() // added to stop click events queuing up
                .attr('aria-hidden', !state)
                .slideToggle();
        });
    });

    // Open first accordion item of each 'group'
    $(options.accordion).each(() => {
        $(this)
            .find(options.accordionTrigger)
            .first()
            .find('button')
            .click();
    });
};

export { init };
