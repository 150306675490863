import * as accordion from './modules/accordion';
import * as carousel from './modules/carousel';
import * as helpers from './modules/helpers';
import * as polyfills from './modules/polyfills';
import * as siteNavigation from './modules/primary-nav';
import * as activeState from './modules/active-state';
import * as card from './modules/card';
import * as cookie from './modules/cookie-policy';

$(function() {
    accordion.init();
    carousel.init();
    helpers.init();
    polyfills.init();
    siteNavigation.init();
    activeState.init();
    card.init();
    cookie.init();
});
